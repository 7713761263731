<template>
  <div id="app-layout">           
    <div class="home__title">
              <h2 class="home__title__bg">SERVICES EN LIGNE</h2>
            </div>         
            <div class="pagecontent">
                <div class="services__row links__presta">
                  <a href="https://www.immobilier.notaires.fr/fr/frais-de-notaire" class="links__presta__block">
                    <i class="fas fa-chart-line links__icon"></i>
                    <div class="links__text">
                      Evaluer<br>vos frais d'achat
                    </div>
                  </a>
                  <a href="https://www.anil.org/outils/outils-de-calcul/diagnostic-de-financement/" class="links__presta__block">
                    <i class="fas fa-euro-sign links__icon"></i>
                    <div class="links__text">
                      Vérifiez<br>vos capacités d'emprunt
                    </div>
                  </a>
                  <a href="https://www.anil.org/outils/outils-de-calcul/echeancier-dun-pret" class="links__presta__block">
                    <i class="fas fa-calculator links__icon"></i>
                    <div class="links__text">
                      Calculez<br>vos remboursement de prêts
                    </div>
                  </a>
                  <a href="http://plus-values.notaires.fr/simulateur/index.xhtml" class="links__presta__block">
                    <i class="fas fa-home links__icon"></i>
                    <div class="links__text">
                      Calculez<br>vos plus-values immobilières
                    </div>
                  </a>
                </div>
                <div class="services__row links__presta">
                  <a href="https://notaviz.notaires.fr/services/donner-et-designer-ses-heritiers" class="links__presta__block">
                    <i class="fas fa-hand-holding-heart links__icon"></i>
                    <div class="links__text">
                      Effectuer<br>une donation
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/acheter" class="links__presta__block">
                    <i class="fas fa-house-user links__icon"></i>
                    <div class="links__text">
                      Acheter<br>un bien immobilier
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/se-separer-ou-divorcer" class="links__presta__block">
                    <i class="fas fa-people-arrows links__icon"></i>
                    <div class="links__text">
                      Divorcer,<br>se séparer
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/entreprendre" class="links__presta__block">
                    <i class="fas fa-briefcase links__icon"></i>
                    <div class="links__text">
                      Entreprendre<br>avec les conseils d'un notaire
                    </div>
                  </a>
                </div>
                <div class="services__row links__presta">
                  <a href="https://notaviz.notaires.fr/services/vivre-ou-detenir-des-biens-a-l-etranger" class="links__presta__block">
                    <i class="fas fa-globe-europe links__icon"></i>
                    <div class="links__text">
                      Vivre<br>à l'étranger
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/vendre" class="links__presta__block">
                    <i class="fas fa-check-circle links__icon"></i>
                    <div class="links__text">
                      Vendre<br>un bien immobilier
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/louer" class="links__presta__block">
                    <i class="fas fa-building links__icon"></i>
                    <div class="links__text">
                      Louer<br>un bien immobilier
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/optimiser-son-patrimoine" class="links__presta__block">
                    <i class="fas fa-coins links__icon"></i>
                    <div class="links__text">
                      Optimiser<br>votre patrimoine
                    </div>
                  </a>
                </div>
                <div class="services__row links__presta">
                  <a href="https://notaviz.notaires.fr/services/s-unir" class="links__presta__block">
                    <i class="fas fa-heart links__icon"></i>
                    <div class="links__text">
                      Se pacser,<br>se marier
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/adopter" class="links__presta__block">
                    <i class="fas fa-baby links__icon"></i>
                    <div class="links__text">
                      Adopter<br>un enfant
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/heriter" class="links__presta__block">
                    <i class="fas fa-scroll links__icon"></i>
                    <div class="links__text">
                      Successions<br>et héritages
                    </div>
                  </a>
                  <a href="https://notaviz.notaires.fr/services/proteger-prevoir" class="links__presta__block">
                    <i class="fas fa-shield-alt links__icon"></i>
                    <div class="links__text">
                      Protéger<br>l'avenir de vos proches
                    </div>
                  </a>
                </div>
            </div>
  </div>
</template>

<script>
  export default {
    metaInfo: {
    // Children can override the title.
    title: 'Services en ligne - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Services'}
   ]
  },
    name: "services"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}
@keyframes service {
    0% {
        transform: translateY(10px);
        opacity: 0;
  }
      
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@for $i from 1 through 9 {
    .links__presta__block:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.25}s; }
 }

.links__presta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 610px){
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
         }
    &__block {
            text-align: center;
            margin: 0 10px;
            width: 25%;
            animation: service 1000ms;
            animation-fill-mode: backwards;
            @media screen and (max-width: 610px){
                margin: 20px 10px;
                width: fit-content;
                 }
        }
}

</style>